<template>
<v-container fluid>
  <v-row style="margin-top: 0px">
    <v-col
      sm="12"
      lg="12"
      md="12"
      xs="12"
      style="min-height: 700px"
    >
      <chart
        key="awaiting-dispatch-report"
        width="100%"
        report="aclreport_awaitingdispatch"
        :chart-params="chartParams"
        :table-params="tableParams"
        :filters="filters"
        style="min-height: 500px"
        @row-clicked="rowClicked"
      />
    </v-col>
  </v-row>
</v-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";

const transactionsNamespace = createNamespacedHelpers("transaction");
const usersNamespace = createNamespacedHelpers("users");

const Chart = () => import("@/components/chart/companies/chart.vue");

import companies from "@/data/companies";
import sectors from "@/data/sectors";

export default {
  components: {
    Chart,
  },
  computed: {
    ...transactionsNamespace.mapState({
      transactionType: (state) => state.transactionType,
    }),
    ...usersNamespace.mapState({
      wallet: (state) => state.wallet,
    }),
  },
  data() {
    return {
      drawer: null,
      type: "DEFAULT",
      filters: [
        {
          source: "list",
          itemText: "name",
          itemValue: "code",
          label: "Sector",
          column: "company",
          value: null,
          list: sectors.map((item) => {
            return {
              code: item.code,
              name: item.name
            }
          })
        },
                {
          source: "list",
          itemText: "name",
          itemValue: "code",
          label: "Company",
          column: "company",
          value: null,
          list: companies.map((item) => {
            return {
              code: item.code,
              name: item.name
            }
          })
        },
      ],
      chartParams: {
        businessCode: "NG000593",
        title: "Companies",
        x: "x",
        valueColumn: "to_amount",
        labelColumn: "status",
        conditions: {
          delivery_type: "COLLECTION",
        },
        valueColumns: [
          { value: "to_amount", text: "Total Pay Out", currencyKey: "to_currency_code" },
          { value: "from_amount", text: "Total Sent", currencyKey: "from_currency_code" },
          { value: "payout_fee", text: "Total Pay Out Fee", currencyKey: "to_currency_code" },
          { value: "total_count", text: "Number Of Transactions" },
        ]
      },
      tableParams: {
        report: "acl_awaitingdispatch_payouts",
        title: "Awaiting Dispatch",
        x: "x",
        valueColumn: "to_amount",
        labelColumn: "cashier_name",
        conditions: {
          delivery_type: "COLLECTION",
        },
        valueColumns: [
          { value: "i_id", text: "ID" },
          { value: "b_vat_number", text: "VAT No." },
          { value: "i_invoice_number", text: "Invoice No." },
          { value: "b_name", text: "Business Name" },
          { value: "i_amount", text: "Amount" },
          { value: "i_vat_amount", text: "VAT Total" },
          { value: "i_status", text: "Status" },
        ],
        exportColumns: [
          { field: "sender", label: "Sender", currencyKey: "to_currency_code" },
          { field: "sender_mobile_number", label: "Sender Mobile", currencyKey: "to_currency_code" },
          { field: "recipient", label: "Recipient", currencyKey: "to_currency_code" },
          { field: "recipient_mobile_number", label: "Recipient Mobile", currencyKey: "to_currency_code" },
          { field: "created_at", label: "Date", currencyKey: "to_currency_code" },
          { field: "from_amount", label: "From Amount", currencyKey: "to_currency_code" },
          { field: "to_amount", label: "Total Pay Out", currencyKey: "to_currency_code" },
          { field: "payout_fee", label: "Total Pay Out Fee", currencyKey: "to_currency_code" },
          { field: "source_tenant", label: "Source" },
        ]
      },
    };
  },
  methods: {
    rowClicked(item) {
      console.log("item", item);
      // this.$router.push({ name: 'send-money-payout-details', params: { secret: item.secret } });
    }
  },
  beforeMount() {
  },
  mounted() {
    // this.aGetWalletList();

    console.log("type", this.transactionType);
  },
};
</script>